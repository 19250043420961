import { UniRoColumn } from "./Bit/UniRoColumn";
import { CustomAuthForm } from "./CustomAuth/CustomAuthForm";
import UsersMenuItem from "./Users/UsersMenuItem";

export const PluginsMap: any = {

    'bit-UniRoColumn': UniRoColumn,


    'custom.user.genToken': UsersMenuItem,
    
    'custom.routes': {
        '/c/custom-sign-up': CustomAuthForm,
    }
}